var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "title secondary--text" }, [
              _vm._v("Select organization"),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-select", {
                staticClass: "mt-5",
                attrs: { items: _vm.sortedOrgNames, outlined: "" },
                model: {
                  value: _vm.selectedOrg,
                  callback: function ($$v) {
                    _vm.selectedOrg = $$v
                  },
                  expression: "selectedOrg",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [_vm._v("close")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.fetchOrgSpaces },
                },
                [_vm._v("confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }